<template>
  <v-row class="fill-height">
    <v-col class="col-md-6 col-lg-7 pt-0 pb-0">
      <v-container class="upload-wrapper pt-0">
        <v-container class="upload-wrapper-inner pt-0">
          <div
            class="text-sm-h5 text-md-h5 text-lg-h5 text-xl-h5 font-weight-bold"
          >
            Dokumente hochladen
          </div>
          <div
            class="text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-bold"
          >
            1. Gutachten
          </div>
          <vue-dropzone
            v-bind:style="
              'height:' +
                calcHeightFromLG(300) +
                ';max-height:' +
                calcHeightFromLG(300) +
                ';'
            "
            ref="dropzoneGutachten"
            id="dropzone-gutachten"
            :awss3="awss3"
            v-on:vdropzone-file-added="fileadd"
            v-on:vdropzone-s3-upload-error="s3UploadError"
            v-on:vdropzone-s3-upload-success="s3UploadSuccess"
            :options="dropzoneOptions"
          >
          </vue-dropzone>
        </v-container>

        <v-container class="upload-wrapper-inner pt-0">
          <div
            class="text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-bold"
          >
            2. Prüfbericht
          </div>
          <vue-dropzone
            v-bind:style="
              'height:' +
                calcHeightFromLG(300) +
                ';max-height:' +
                calcHeightFromLG(300) +
                ';'
            "
            ref="dropzonePruefbericht"
            id="dropzone-pruefbericht"
            :awss3="awss3pb"
            v-on:vdropzone-s3-upload-error="pbs3UploadError"
            v-on:vdropzone-s3-upload-success="pbs3UploadSuccess"
            v-on:vdropzone-error="pbsUploadError"
            :options="dropzoneOptions"
          >
          </vue-dropzone>
        </v-container>
      </v-container>

      <div class="alert alert-warning" v-if="error">{{ error }}</div>
      <div class="alert alert-info" v-if="status">{{ status }}</div>
    </v-col>

    <v-col class="col-md-6 col-lg-5 form-back shadow pa-0">
      <v-card elevation="0">
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
          <!--          <v-btn color="success" @click="overlay = false">-->
          <!--            Hide Overlay-->
          <!--          </v-btn>-->
        </v-overlay>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container class="pl-5 pr-5">
            <div
              class="text-sm-h5 text-md-h5 text-lg-h5 text-xl-h5 font-weight-bold"
            >
              Auftragsdaten
            </div>
            <div
              class="text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-bold"
            >
              Kundendaten
            </div>
            <v-radio-group
              class="pb-1"
              dense
              hide-details
              row
              mandatory
              v-model="getAuftragsDetails.kunde.anrede"
            >
              <template v-slot:label>
                <div>Anrede:</div>
              </template>
              <v-radio label="Herr" value="Herr"></v-radio>
              <v-radio label="Frau" value="Frau"></v-radio>
            </v-radio-group>
            <v-row>
              <v-col class="pt-2">
                <v-text-field
                  v-model="getAuftragsDetails.kunde.vorname"
                  label="Vorname"
                  required
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pt-2">
                <v-text-field
                  v-model="getAuftragsDetails.kunde.nachname"
                  label="Nachname"
                  required
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="pt-2">
                <v-text-field
                  v-model="getAuftragsDetails.kunde.plz"
                  label="PLZ"
                  required
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8" class="pt-2">
                <v-text-field
                  v-model="getAuftragsDetails.kunde.ort"
                  label="Ort / Stadt"
                  required
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-2">
                <v-text-field
                  v-model="getAuftragsDetails.kunde.strasse"
                  label="Straße und Hausnummer"
                  required
                  outlined
                  hide-details
                  dense
                  class="mb-2"
                ></v-text-field>
              </v-col>
            </v-row>
            <span
              >(optional) Aktenzeichen, Kontaktdaten des Kunden für Rückfragen
              zum Auftrag</span
            >
            <v-row>
              <v-col cols="12" md="4" class="pt-2">
                <v-text-field
                  class="pa-0"
                  v-model="getAuftragsDetails.auftrag.aktenzeichen"
                  label="Aktenzeichen"
                  required
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pt-2">
                <v-text-field
                  class="pa-0"
                  v-model="getAuftragsDetails.kunde.email"
                  label="E-Mail"
                  required
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pt-2">
                <v-text-field
                  v-model="getAuftragsDetails.kunde.telefon"
                  label="Telefon"
                  required
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3">
                <v-text-field
                  class="invisible"
                  label="Gutachten Key"
                  required
                  outlined
                  dense
                  v-model="getAuftragsDetails.dokumente.gkey"
                >
                </v-text-field>
                <v-text-field
                  class="invisible"
                  label="Prüfbericht Key"
                  required
                  outlined
                  dense
                  v-model="getAuftragsDetails.dokumente.pkey"
                >
                </v-text-field>
                <span
                  >Lieferzeiten: 1-2 Werktage (Express), 5-6 Werktage
                  (Normal)</span
                >
                <v-radio-group
                  column
                  mandatory
                  row
                  v-model="getAuftragsDetails.auftrag.typ"
                  hide-details
                  class="mt-2"
                >
                  <template v-slot:label>
                    <span>Auftragsart:</span>
                  </template>
                  <v-radio label="Normal" value="Normal"></v-radio>
                  <v-radio label="Express" value="Express"></v-radio>
                </v-radio-group>

                <v-radio-group
                  column
                  row
                  mandatory
                  v-model="getAuftragsDetails.auftrag.besteller"
                  hide-details
                  class="mt-2"
                >
                  <template v-slot:label>
                    <span>Rechnungsempfänger:</span>
                  </template>
                  <v-radio label="Kunde" value="Kunde"></v-radio>
                  <v-radio label="Besteller" value="Besteller"></v-radio>
                </v-radio-group>

                <v-radio-group
                  column
                  row
                  mandatory
                  v-model="getAuftragsDetails.auftrag.rechtsschutz"
                  hide-details
                  class="pb-5 mt-2"
                >
                  <template v-slot:label>
                    <span>Rechtsschutzversicherung vorhanden:</span>
                  </template>
                  <v-radio label="Ja" value="true"></v-radio>
                  <v-radio label="Nein" value="false"></v-radio>
                  <v-radio label="k. A." value="unknown"></v-radio>
                </v-radio-group>

                <h2>Bestellzusammenfassung:</h2>
                <span v-if="getAuftragsDetails.auftrag.typ === 'Normal'"
                  >1x Prüfbericht (PZP), Normale Lieferzeit:
                  <strong>49 €, zzgl. 19% USt.</strong></span
                >
                <span v-if="getAuftragsDetails.auftrag.typ === 'Express'"
                  >1x Prüfbericht (PZP), Express Lieferzeit:
                  <strong>54,90 €, zzgl. 19% USt.</strong></span
                >
              </v-col>
            </v-row>
          </v-container>

          <v-container class="pt-0">
            <v-col>
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="submitform"
              >
                Auftrag erstellen
              </v-btn>

              <v-btn color="error" class="mr-4" @click="reset(undefined)">
                Daten löschen
              </v-btn>
            </v-col>
          </v-container>

          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" text @click="snack = false"> X </v-btn>
            </template>
          </v-snackbar>

          <!--<p><pre> {{ getAuftragsDetails }} </pre></p>-->
        </v-form>
      </v-card>
    </v-col>
    <v-overlay :value="fullOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import vue2Dropzone from "vue2-dropzone";
import vue2Dropzone from "../components/vue-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

//const initialData =

export default {
  name: "CreateNew",
  components: {
    vueDropzone: vue2Dropzone
  },

  mounted: function() {
    this.folderName = this.generateId();
    this.$store.commit("setMainContextName", "Neuer Antrag");
  },

  computed: {
    ...mapGetters(["getAuftragsDetails"])
  },
  data() {
    return {
      //overlay
      absolute: true,
      overlay: false,
      fullOverlay: false,

      // Form Variables
      snack: false,
      snackColor: "",
      snackText: "",

      folderName: "",

      tokenId: this.getAuthBearer,
      s3ObjectLocation: "",
      info: null,
      valid: true,
      vorname: "",
      nachname: "",
      nameRules: [
        v => !!v || "Pflichtfeld",
        v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      email: "",
      emailRules: [
        v => !!v || "Pflichtfeld",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      telefon: "",
      telefonRules: [
        v => !!v || "Pflichtfeld",
        v => v.length <= 10 || "Telefonnummer ist zu lang"
      ],
      plz: "",
      plzRules: [
        v => !!v || "Pflichtfeld",
        v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      stadt: "",
      stadtRules: [
        v => !!v || "Pflichtfeld",
        v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      strasse: "",
      strasseRules: [
        v => !!v || "Pflichtfeld",
        v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      hausnummer: "",
      hausnummerRules: [
        v => !!v || "Pflichtfeld",
        v => v.length <= 10 || "Name must be less than 10 characters"
      ],
      agb: false,
      error: "",
      status: "",
      dropzoneOptions: {
        maxFiles: 1,
        url: "/",
        thumbnailWidth: 200,
        //maxFilesize: 0.5,
        headers: {},
        addRemoveLinks: true,
        autoProcessQueue: true,
        dictRemoveFile: "Datei entfernen",
        dictDefaultMessage:
          "<span class='mdi mdi-36px mdi-cloud-upload'><br>Dokument hier ablegen oder hier klicken</span>"
      },
      awss3: {
        signingURL: f => {
          let filename = f.name.toLowerCase();
          this.getAuftragsDetails.dokumente.gkey = decodeURI(
            "upload/" + this.folderName + "/" + filename
          );
          this.overlay = true;
          return decodeURI(
            this.$store.getters.getAPIContext.endpoint_url_signer +
              "?folder=" +
              this.folderName +
              "&filename=" +
              filename
          );
        },
        headers: {
          Authorization: "Bearer " + this.$store.getters.getAuthBearer,
          Accept: "application/json"
        },
        params: {},
        sendFileToServer: true,
        withCredentials: false
      },
      awss3pb: {
        //TODO: Gleicher Filename ?
        signingURL: f => {
          let filename = f.name.toLowerCase();
          this.getAuftragsDetails.dokumente.pkey = decodeURI(
            "upload/" + this.folderName + "/" + filename
          );
          return decodeURI(
            this.$store.getters.getAPIContext.endpoint_url_signer +
              "?folder=" +
              this.folderName +
              "&filename=" +
              filename
          );
        },
        headers: {
          Authorization: "Bearer " + this.$store.getters.getAuthBearer,
          Accept: "application/json"
        },
        params: {},
        sendFileToServer: true,
        withCredentials: false
      }
    };
  },

  methods: {
    ...mapActions(["fetchAuftragsDetails", "updateAuftragsDetails"]),
    calcHeightFromLG(predefinedLGHeight) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return predefinedLGHeight - 220 + "px";
        case "sm":
          return predefinedLGHeight - 200 + "px";
        case "md":
          return predefinedLGHeight - 170 + "px";
        case "lg":
          return predefinedLGHeight - 140 + "px";
        case "xl":
          return predefinedLGHeight + "px";
        default:
          return predefinedLGHeight + "px";
      }
    },
    reset(invokedSnackText) {
      this.$refs.form.reset();
      this.snack = true;
      this.snackColor = "success";
      window.scrollTo(0, 0);
      this.snackText =
        invokedSnackText !== undefined ? invokedSnackText : "Daten gelöscht.";
    },

    resetData(snackText) {
      this.$refs.dropzoneGutachten.removeAllFiles();
      this.$refs.dropzonePruefbericht.removeAllFiles();
      this.reset(snackText);
    },

    fileadd() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Datei hinzugefügt, Upload startet...";
    },

    generateId() {
      let text = "";
      const possible = "abcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },

    async submitform() {
      if (
        this.getAuftragsDetails.kunde.vorname === null ||
        this.getAuftragsDetails.kunde.vorname === undefined ||
        this.getAuftragsDetails.kunde.vorname.trim().length === 0
      ) {
        alert('Das Feld "Vorname" wurde nicht ausgefüllt');
        return false;
      }
      if (
        this.getAuftragsDetails.kunde.nachname === null ||
        this.getAuftragsDetails.kunde.nachname === undefined ||
        this.getAuftragsDetails.kunde.nachname.trim().length === 0
      ) {
        alert('Das Feld "Nachname" wurde nicht ausgefüllt');
        return false;
      }
      if (
        this.getAuftragsDetails.kunde.plz === null ||
        this.getAuftragsDetails.kunde.plz === undefined ||
        this.getAuftragsDetails.kunde.plz.trim().length === 0
      ) {
        alert('Das Feld "PLZ" wurde nicht ausgefüllt');
        return false;
      }
      if (
        this.getAuftragsDetails.kunde.ort === null ||
        this.getAuftragsDetails.kunde.ort === undefined ||
        this.getAuftragsDetails.kunde.ort.trim().length === 0
      ) {
        alert('Das Feld "Ort" wurde nicht ausgefüllt');
        return false;
      }
      if (
        this.getAuftragsDetails.kunde.strasse === null ||
        this.getAuftragsDetails.kunde.strasse === undefined ||
        this.getAuftragsDetails.kunde.strasse.trim().length === 0
      ) {
        alert('Das Feld "Strasse" wurde nicht ausgefüllt');
        return false;
      }

      this.fullOverlay = true;
      console.log("auftragsdaten anzeigen");
      console.log(this.getAuftragsDetails);
      console.log("auftragsdaten senden");
      const reqResp = await this.updateAuftragsDetails(this.getAuftragsDetails);
      if (reqResp !== undefined && reqResp === 200) {
        this.resetData("Antrag wurde versendet");
      } else if (reqResp === 400) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText =
          "Fehlgeschlagen. Überprüfen Sie bitte die Eingaben und Dokumente.";
      }
      this.fullOverlay = false;
    },
    s3UploadError(file) {
      console.log("s3UploadError:\n" + file);
      //disable form overlay
      this.overlay = false;
      console.log("s3UploadError:");
      console.log(file);

      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Upload fehlgeschlagen.";
    },
    s3UploadSuccess(s3ObjectLocation) {
      this.s3ObjectLocation = s3ObjectLocation;

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Upload erfolgreich";

      //show overlay
      this.fetchAuftragsDetails(this.s3ObjectLocation).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Daten erfolgreich extrahiert.";
        //disable form overlay
        this.overlay = false;
      });
    },
    pbs3UploadError(file) {
      console.log("pbs3UploadError:\n" + file);
    },
    pbs3UploadSuccess(s3ObjectLocation) {
      console.log("enter pbs3UploadSuccess");
      this.s3ObjectLocation = s3ObjectLocation;
      console.log("pfad:");
      console.log(decodeURI(s3ObjectLocation));
    },
    pbsUploadError(file, message, xhr) {
      console.log("enter pbsUploadError");
      console.log("msg:" + message);
      if (file !== undefined) console.log("s1:");
      if (xhr !== undefined) console.log("s2:");
    }
  }
};
</script>

<style>
.shadow {
  -webkit-box-shadow: 4px 0px 10px 5px #000000;
  box-shadow: 8px 0px 10px 5px #000000;
}

.invisible {
  display: none;
}

.form-back {
  background-color: white;
}

.dropzone:hover {
  background-color: white;
  border-color: #3498db;
}

.dropzone {
  transition: all 0.2s linear;
  border: 2px dashed #ccc;
  background-color: #fafafa;
  /*min-height: 300px;*/
}

.upload-wrapper {
  /*background-color: white;*/
  padding: 20px;
  /*border-radius: 5px;*/
  margin-top: 0px;
  /*border: 1px solid #e4e6e7;*/
  /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);*/
}
.upload-wrapper-inner {
  padding-top: 30px;
  padding-bottom: 30px;
}

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  /*background: lightcyan;*/
  background: transparent;
  color: dimgray;
  padding: 10px 10px;
  min-height: 400px; /* minimum height */
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 400px;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: red; /* when mouse over to the drop zone, change color */
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

div.dz-preview.dz-file-preview,
div.dz-preview.dz-file-preview:hover {
  z-index: 4;
}
</style>
